@tailwind base;
@tailwind components;
@tailwind utilities;

// TODO: Remove tailwind. We should be able to steal the few styles we use and just do normal css.

body {
  font-family: "Major Mono Display", monospace;

  a {
    &:hover,
    &:focus {
      // white and slate-900
      background-color: rgb(255, 255, 255);
      color: rgb(15, 23, 42);
    }
  }
}
